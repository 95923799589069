import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';

import { splitAt } from 'ramda';
import { useParams } from 'react-router';
import useAuth from '~/hooks/useAuth';
import { ButtonAnt as Button, ProjectDetailsHeader, notification } from '~/UI';
import { isUserAdmin, NODE_ENVS, projectIsClosed, theme } from '~/utils';
import {
  TEST_DATA_COMPONENTS,
  TEST_DATA_PAGES,
  TestIdProps,
  generateTestId,
} from '~/utils/dataTestProps';
import {
  useGetStorageEngineResultsQuery,
  useGetStorageProjectV3Query,
  useSaveStorageFiltersMutation,
} from '~/store/api';
import { SpinnerMask } from '~/UI/Spinner';
import {
  getStorageProjectDetailsItems,
  isStatusClosed,
} from '~/constants/projects';
import LoadingCard from '~/components/LoadingCard';
import useHandleProjectError from '~/hooks/useHandleProjectError';
import useStoragePermissions from '~/hooks/Permissions/useStoragePermissions';
import SelectFilterMenu from '~/UI/Filters_WIP/SelectFilterMenu';
import { generateSchemaFilters } from '~/utils/analyticsData';
import { useFlags } from '~/utils/launchdarkly';
import {
  GTM_EVENTS,
  GTM_SOURCE_IDS,
  gtmSetCurrentProjectState,
} from '~/utils/gtm';
import StorageProjectDrawer from '../components/Projects/storage/StorageProjectDrawer';
import StorageModuleTable, {
  IndexedStorageRanking,
} from '../components/Projects/storage/StorageModuleTable';
import { FilterValues } from '~/UI/Filters/FilterForm';
import { filterDataByAnalytics } from '~/utils/filters';
import { IconPencil } from '~/UI/Icons';
import { getConfiguration } from '~/utils/configurations';
import { AnzaEngineStatus } from '~/store/project';
import { ANZA_ENGINE_RUN_STATUS } from '~/constants/engine';

const FilterFlexContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

const HeaderFooterContainer = styled.div`
  padding-bottom: 5px;
`;

const PATH_TO_MODULE_P360 = ['dc_block', 'p360'];

const dataTestIdConfig: TestIdProps = {
  page: TEST_DATA_PAGES.CUSTOMER.STORAGE_PROJECT_DETAILS,
  component: TEST_DATA_COMPONENTS.BUTTON,
};

export type ProjectStorageDetailsProps = {
  setBanner: (banner: React.ReactNode) => void;
};

const ProjectStorageDetails = ({ setBanner }: ProjectStorageDetailsProps) => {
  const { user } = useAuth();
  const flags = useFlags();
  const { handleProjectError } = useHandleProjectError();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [hideShadow, setHideShadow] = useState<boolean>(true);
  const [filterValues, setFilterValues] = useState<FilterValues>({});

  const { uuid } = useParams<{ uuid: string }>();
  const {
    data,
    isLoading,
    isFetching,
    error: errorGetProject,
  } = useGetStorageProjectV3Query({
    id: uuid,
  });
  const [saveStorageFilters] = useSaveStorageFiltersMutation();
  const projectStatus = data?.project?.project_details?.status;
  const skipGettingEngineResults =
    projectStatus && projectIsClosed(projectStatus) && !isUserAdmin(user);
  const [storageEngineStatus, setStorageEngineStatus] =
    useState<AnzaEngineStatus>();
  const {
    data: storageEngine,
    isFetching: isFetchingStorageEngine,
    isLoading: isLoadingStorageEngine,
    refetch: refetchStorageEngine,
  } = useGetStorageEngineResultsQuery(
    {
      id: uuid,
    },
    {
      pollingInterval:
        storageEngineStatus === ANZA_ENGINE_RUN_STATUS.COMPLETED ||
        storageEngineStatus === ANZA_ENGINE_RUN_STATUS.FAILED
          ? undefined
          : 1000,
      skip: !data || skipGettingEngineResults,
    }
  );

  useEffect(() => {
    handleProjectError(errorGetProject);
  }, [errorGetProject]);

  useEffect(() => {
    if (storageEngine?.status && storageEngineStatus !== storageEngine.status) {
      setStorageEngineStatus(storageEngine.status);
    }
  }, [storageEngine?.status, storageEngineStatus]);

  useEffect(() => {
    if (data) {
      const projectDetails = data.project.project_details;

      gtmSetCurrentProjectState(
        GTM_EVENTS.STAGE_CHANGED,
        GTM_SOURCE_IDS.ROOTS.STORAGE_PROJECT,
        {
          uuid: data.project.uuid,
          name: data.project.name,
          projectType: projectDetails.project_type,
          ...(projectDetails?.locationModifier && {
            location: `${projectDetails?.locationModifier?.city}, ${projectDetails?.locationModifier?.state}`,
          }),
        }
      );
    }
  }, [storageEngine, data]);

  const handleOnOpenDrawer = () => setIsDrawerOpen(true);
  const handleOnCloseDrawer = () => setIsDrawerOpen(false);
  const { header, secondaryHeader } = theme.elements;
  const projectHeaderOffset = isUserAdmin(user)
    ? header.heightNumber + secondaryHeader.heightNumber
    : header.heightNumber;

  const isEngineActive = storageEngineStatus === ANZA_ENGINE_RUN_STATUS.ACTIVE;

  if (
    !isEngineActive &&
    (isLoading ||
      isFetching ||
      isLoadingStorageEngine ||
      isFetchingStorageEngine)
  )
    return <SpinnerMask />;
  if (!data) return null;

  const { enableAdvanceInputs, modules } = useStoragePermissions(
    data.permissions
  );

  const [heroModuleItems, heroAdditionalItems] = splitAt(
    4,
    getStorageProjectDetailsItems(data.project)
  );

  const isEditButtonDisabled =
    isEngineActive || isStatusClosed(data.project.project_details.status);
  const schemas = storageEngine?.analytics_data_schema || {};
  const filteredSchemas = R.filter((s) => s.is_filter_option, schemas);
  const storageRanking = storageEngine?.storageRanking;

  // TODO: Temporary fix to support V2 engine results. Need a better way to handle versioned results.
  const storageRankingMappedForVersion = storageRanking?.map((i) => ({
    ...i,
    dc_block: {
      ...i.dc_block,
      UUID: i.dc_block?.UUID ?? i.dc_block?.uuid,
      ProductName: i.dc_block?.ProductName ?? i.dc_block?.product_name,
      Manufacturer: i.dc_block?.Manufacturer ?? i.dc_block?.manufacturer,
    },
    pcs_module: {
      ...i.pcs_module,
      UUID: i.pcs_module?.UUID ?? i.pcs_module?.uuid,
      ProductName: i.pcs_module?.ProductName ?? i.pcs_module?.product_name,
      Manufacturer: i.pcs_module?.Manufacturer ?? i.pcs_module?.manufacturer,
    },
  }));
  const storageRankingIndexed: IndexedStorageRanking[] =
    storageRankingMappedForVersion?.map((module, index) => ({
      ...module,
      index,
      key: `${module.dc_block.UUID} ${module.pcs_module.UUID}`,
    })) || [];

  const filteredStorageRanking = storageRankingIndexed.filter((module) =>
    filterDataByAnalytics(module, filterValues, {
      pathToAnalyticsData: PATH_TO_MODULE_P360,
    })
  );

  const handleSaveFilters = () => async () => {
    await saveStorageFilters({
      projectUuid: data.project.uuid,
      filters: filterValues,
    });
    notification.success({
      duration: 3,
      message: 'Your project was saved.',
    });
  };

  const isDemo2 =
    getConfiguration('REACT_APP_DEPLOYMENT_ENV') === NODE_ENVS.DEMO2;

  return (
    <>
      <ProjectDetailsHeader
        setBanner={setBanner}
        showToggle
        user={user}
        dataTestIdConfig={dataTestIdConfig}
        offsetTop={projectHeaderOffset}
        project={data.project}
        heroModuleItems={heroModuleItems}
        heroModuleAdditionalItems={heroAdditionalItems}
        hideBottomShadow={hideShadow}
        onAffixChange={(value) => setHideShadow(!value)}
        footer={
          <HeaderFooterContainer>
            {schemas && flags.storageFilters && (
              <FilterFlexContainer>
                <SelectFilterMenu
                  key="storage-filters"
                  filters={generateSchemaFilters(
                    filteredSchemas,
                    storageRanking || [],
                    {
                      pathToSchemaValue: PATH_TO_MODULE_P360,
                    }
                  )}
                  onChange={setFilterValues}
                />
              </FilterFlexContainer>
            )}
          </HeaderFooterContainer>
        }
      >
        <Button
          disabled={isEditButtonDisabled}
          onClick={handleOnOpenDrawer}
          square
          data-testid={generateTestId({
            ...dataTestIdConfig,
            identifier: 'edit-project',
          })}
        >
          <IconPencil size={24} />
        </Button>
      </ProjectDetailsHeader>
      {isEngineActive ? (
        <LoadingCard
          textLines={[
            'Gathering AC / DC Block and PCS pricing',
            'Analyzing component compatibilities',
            'Collecting location and weather data',
            'Estimating capital and operating expenses',
            'Calculating capacity maintenance strategies and augmentation costs',
            'Preparing your engine results',
          ]}
        />
      ) : (
        <StorageModuleTable
          storageEngineStatus={storageEngineStatus}
          storageRanking={filteredStorageRanking}
          project={data.project}
          handleOnOpenDrawer={handleOnCloseDrawer}
          isLoading={isLoadingStorageEngine || isFetchingStorageEngine}
          showEngagedEmptyState={modules.showEngagedEmptyState}
          showDetailTabAsRedacted={modules.showDetailTabAsRedacted || isDemo2}
          enableCSVSummaryDownload={
            modules.enableCSVSummaryDownload && !isDemo2
          }
        />
      )}
      <StorageProjectDrawer
        onCloseDrawer={handleOnCloseDrawer}
        isOpen={isDrawerOpen}
        projectData={data.project}
        advancedInputs={data.project.advancedInputs}
        enableAdvanceInputs={enableAdvanceInputs}
        onSaveStorageProject={() => {
          setStorageEngineStatus(ANZA_ENGINE_RUN_STATUS.ACTIVE);
          refetchStorageEngine();
        }}
      />
    </>
  );
};

export default ProjectStorageDetails;
