export interface Company {
  id: number;
  name: string;
  company_type: string;
  account_manager_user_id?: number;
  previous_purchase_volume_mw?: string;
  nda_expiration_date?: Date;
  is_automatic_approval?: boolean;
  file_data?: FileData;
}
export interface CreateCompanyResultType {
  id: number;
}
export type UpdateCompanyResultType = CreateCompanyResultType;
export type CreateCompanyArguments = Omit<Company, 'id'>;
export type UpdateCompanyArguments = Company;
export interface FileData {
  content_type: string;
  file_name: string;
}

export enum ProductName {
  FREE_PREVIEW = 'Free Preview',
  ESSENTIALS = 'Essentials',
  PRO = 'Pro',
  PRO_PROCURE = 'Pro Procure',
  PRO_OPTIMIZE = 'Pro Optimize',
}

export type CompanyProduct = {
  product_id: string;
  product_offerings: {
    product: ProductName;
  };
};

export interface ProductOfferings {
  id: string;
  product: ProductName;
}
export type ProductOfferingsQueryResult = ProductOfferings[];
