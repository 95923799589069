import { UserType, CompanyType } from '../types/users';

interface CompanyTypes {
  [key: string]: {
    code: CompanyType['company_type'];
    name: string;
  };
}

export const ROLES: Record<string, string> = {
  admin: 'Admin',
  customer: 'Customer',
  account_manager: 'Account Manager',
  vendor: 'Vendor',
};

export const COMPANY_TYPES: CompanyTypes = {
  corp: {
    name: 'Corporation',
    code: 'CORP',
  },
  consumer: {
    name: 'Customer',
    code: 'CUSTOMER',
  },
  vendor: {
    name: 'Vendor',
    code: 'VENDOR',
  },
};

const isOfThisRole = (role: string) => {
  return (user: UserType | { auth0_roles: string[] }) => {
    const { auth0_roles } = user;
    return auth0_roles?.includes(role) ?? false;
  };
};

export const isUserCompanyEngagedSolar = (user: UserType) => {
  return !!user.company?.engaged_solar;
};

export const isUserAdmin = isOfThisRole('Admin');
export const isUserAccountManager = isOfThisRole('Account Manager');
export const isUserCustomer = isOfThisRole('Customer');
export const isUserVendor = isOfThisRole('Vendor');
