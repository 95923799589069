import React, { memo } from 'react';
import { useHistory } from 'react-router';
import LookerEmbed from '~/components/LookerEmbed';
import { useFlags } from '~/utils/launchdarkly';

const PRICING_HISTORY = 'buyer_pricing_history';

const PricingHistory = () => {
  const { newProjectFlow } = useFlags();
  const history = useHistory();
  if (newProjectFlow) history.push('/404');
  return <LookerEmbed id={PRICING_HISTORY} />;
};

export default memo(PricingHistory);
