import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { UserPayload } from './types';
import { UserType } from '../../types/users';

export const initialState: UserType = {
  id: null,
  uuid: null,
  token: null,
  email: null,
  is_system_admin: null,
  created_at: null,
  last_active_at: null,
  updated_at: null,
  job_title: null,
  company: null,
  email_verified: null,
  entitlements: {
    redactedExperience: false,
    anzaClientAccess: false,
    horizon: false,
    moduleRanking: false,
    timeSaver: false,
    editProject: false,
    solarBase: false,
    marketIntel: false,
    customizePricing: false,
  },
  products: [],
};

const storeUser: CaseReducer<UserType, PayloadAction<UserPayload>> = (
  state,
  action
) => {
  return {
    ...state,
    id: action.payload.id,
    uuid: action.payload.uuid,
    first_name: action.payload.first_name,
    last_name: action.payload.last_name,
    email: action.payload.email,
    company: action.payload.company,
    email_verified: action.payload.email_verified,
    job_title: action.payload.job_title,
    module_digest_opt_out: action.payload.module_digest_opt_out,
    vendor_module_pricing_notifications_opt_out:
      action.payload.vendor_module_pricing_notifications_opt_out,
    auth0_roles: action.payload.auth0_roles,
    entitlements: action.payload.entitlements,
  };
};

const logoutUser: CaseReducer<UserType> = () => {
  return initialState;
};

const reducers = {
  storeUser,
  logoutUser,
};

export default reducers;
