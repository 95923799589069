import React, { useEffect, useCallback, useReducer, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import dayjs from 'dayjs';
import { ConfigProvider } from 'antd';
import debounce from 'lodash.debounce';
import styled from '@emotion/styled';

import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import type { ColumnsType } from 'antd/lib/table';
import useAuth from '~/hooks/useAuth';
import {
  setPageBanner as setPageBannerAction,
  removePageBanner as removePageBannerAction,
} from '~/store/ui';
import Row from '~/UI/Row';
import {
  getProjectTypeClientFacingValueOrDefault,
  PROJECT_STATUSES,
  PROJECT_STATUS_STYLES,
  PROJECT_TYPES,
  PROJECT_STATUSES_BUYER,
  projectStatusesBuyerArray,
} from '~/constants/projects';
import { blockEvent } from '~/utils/events';
import { useDeleteProjectMutation, useGetProjectListQuery } from '~/store/api';
import {
  ButtonAnt as Button,
  CustomDrawerHeader,
  Drawer,
  Table,
  Tag,
  Tooltip,
} from '~/UI';
import { IconAdd, IconTrash } from '~/UI/Icons';
import { ActionRow, ActionButton } from '~/UI/ActionBar';
import { EmptyTableContainer } from '~/UI/Table';
import AnzaContainer from '~/components/Anza/AnzaLayout/AnzaContainer';
import {
  toApiParams,
  theme,
  paramsReducer,
  InitialState,
  isUserAdmin,
} from '~/utils';
import {
  generateTestId,
  TEST_DATA_PAGES,
  TEST_DATA_CATEGORIES,
  TEST_DATA_COMPONENTS,
  TestIdProps,
} from '~/utils/dataTestProps';
import { ROUTER_PATHS } from '~/router/Paths';
import SearchFilterDropdown from '~/components/Tables/SearchFilterDropdown';
import { toggleSearchModal, resetSearchModal } from '~/store/searchProject';
import CreateProject from '~/components/Projects/CreateProject';
import DeleteModalConfirm from '~/components/Projects/DeleteModalConfirm';
import ScheduleCallDialog from '~/components/Anza/ScheduleDialog/ScheduleDialog';
import { BANNER_TYPES } from '~/components/PageBanner';
import { ProjectResume } from '~/store/project';
import { useFlags } from '~/utils/launchdarkly';
import PricingHistoryBanner from '../components/Projects/PricingHistoryBanner';

const Container = styled(AnzaContainer)`
  overflow: hidden;
`;

const Title = styled.div`
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.05em;
  text-align: left;
  color: ${theme.fonts.colors.charcoal};
`;

const EmptyTitle = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.05em;
  color: ${theme.colors.charcoal};
  margin-bottom: 1rem;
`;

const EmptyDescription = styled.div`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${theme.colors.charcoal};
  margin-bottom: 3.5rem;
`;

const DeleteButton = styled(Button)`
  border: none;
  min-height: 100%;
  min-width: 100%;
  color: transparent;
  cursor: pointer;
`;

const ProjectTable = styled(Table<ProjectResume>)`
  width: 100%;
  border-radius: 14px;
  overflow: auto hidden;
  .ant-table-container {
    border-radius: 14px;
  }
  .ant-table {
    border-radius: 14px;
  }
  .ant-table-content {
    border: 1px solid ${theme.colors.sterling};
    border-radius: 14px;
    overflow: auto hidden;
    th {
      background: white !important;
      font-weight: 600;
      &::before {
        display: none;
      }
    }
  }
  .ant-table-row {
    cursor: pointer;
    .overflowText {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .action {
      button {
        svg {
          margin-left: -20px;
        }
      }
    }
    :hover {
      .action {
        button {
          color: ${theme.colors.stone};
        }
      }
    }
  }
`;

const StyledDrawer = styled(Drawer)`
  height: calc(100vh - ${theme.elements.header.height}) !important;

  .ant-drawer-mask {
    margin-top: 0 !important;
  }
`;

const dataTestIdConfig: TestIdProps = {
  category: TEST_DATA_CATEGORIES.CUSTOMER,
  page: TEST_DATA_PAGES.CUSTOMER.PROJECTS,
  component: '',
};

type GetColumnsParams = {
  handleSearch: (selectedKeys: string[], dataIndex: string) => void;
  openDeleteModalConfirm: (
    id: string
  ) => (event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;
};
const getColumns = ({
  handleSearch,
  openDeleteModalConfirm,
}: GetColumnsParams): ColumnsType<ProjectResume> | undefined => [
  {
    title: `Project Name`,
    dataIndex: 'name',
    key: 'project',
    fixed: true,
    width: '250px',
    className: 'overflowText',
    sorter: true,
    ...SearchFilterDropdown(
      {
        dataIndex: 'name',
        filters: [],
      },
      {
        handleSearch,
      }
    ),
    render: (projectName: string) => (
      <Tooltip placement="topLeft" title={projectName}>
        <span data-testid="project-name-cell">{projectName}</span>
      </Tooltip>
    ),
  },
  // TODO: SB-1107 Delete
  {
    title: 'Project Type',
    dataIndex: 'project_type',
    key: 'project_type',
    sorter: true,
    width: '150px',
    onFilter: (value, record) =>
      R.equals(
        R.toLower(value as string),
        R.isNil(record.project_type) ? '' : R.toLower(record.project_type)
      ),
    filters: [
      {
        text: PROJECT_TYPES.solar.clientFacingValue,
        value: PROJECT_TYPES.solar.key,
      },
      {
        text: PROJECT_TYPES.storage.clientFacingValue,
        value: PROJECT_TYPES.storage.key,
      },
      {
        text: PROJECT_TYPES.solarStorage.clientFacingValue,
        value: PROJECT_TYPES.solarStorage.key,
      },
    ],
    render: (projectType: string) => (
      <Tag
        text={getProjectTypeClientFacingValueOrDefault(
          projectType,
          projectType
        )}
      />
    ),
  },
  {
    title: 'Location',
    dataIndex: 'project_address',
    key: 'project_address',
    sorter: true,
    className: 'overflowText',
    width: '300px',
    ...SearchFilterDropdown(
      {
        dataIndex: 'project_address',
        filters: [],
      },
      {
        handleSearch,
      }
    ),
    render: (project_address: string) => (
      <Tooltip placement="topLeft" title={project_address}>
        <span data-testid="project-address-cell">{project_address}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    sorter: true,
    width: '150px',
    onFilter: (value, record) => {
      if (value === PROJECT_STATUSES.inactive.key) {
        return [
          PROJECT_STATUSES.inactive.key,
          PROJECT_STATUSES.awarded.key,
          PROJECT_STATUSES.lost.key,
        ].includes(record.status);
      }
      return R.equals(
        R.toLower(value as string),
        R.isNil(record.status) ? '' : R.toLower(record.status)
      );
    },
    filters: projectStatusesBuyerArray,
    render: (text: string) => {
      const value = PROJECT_STATUSES[text].clientFacingValue;
      const style = PROJECT_STATUS_STYLES[text] || {};
      return <Tag text={value} style={style} />;
    },
  },
  {
    title: 'Delivery Date',
    dataIndex: 'arrival_date',
    key: 'arrival_date',
    sorter: true,
    width: '170px',
    render: (date: string) => (
      <span data-testid="arrival_date">
        {dayjs(date, 'YYYY/MM/DD').format('MMM YYYY')}
      </span>
    ),
  },
  {
    title: 'Last Updated',
    dataIndex: 'updated_at',
    key: 'updated_at',
    sorter: true,
    width: '170px',
    render: (date: string) => (
      <span data-testid="updated_at">
        {dayjs(date).format('MMM DD, YYYY')}{' '}
      </span>
    ),
  },
  {
    dataIndex: 'uuid',
    key: 'uuid',
    width: '50px',
    className: 'action',
    render: (id: string) => (
      <DeleteButton
        data-testid="project-delete-button"
        onClick={openDeleteModalConfirm(id)}
      >
        <IconTrash size="24px" />
      </DeleteButton>
    ),
  },
];

const ProjectsList = () => {
  const { user } = useAuth();
  const isAdmin = isUserAdmin(user);
  const history = useHistory();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(0);
  const [selectedAnzaId, setSelectedAnzaId] = useState('');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [deleteProject] = useDeleteProjectMutation();
  const { solarPricingVisualization, newProjectFlow } = useFlags();
  const showPricingVisualization =
    solarPricingVisualization && user.company?.engaged_solar;
  const [params, dispatchParams] = useReducer(paramsReducer, {
    filters: {},
    pagination: {
      current: 1,
      pageSize: 20,
      total: 0,
      showSizeChanger: false,
    },
    sorter: {
      sortField: 'updated_at',
      sortOrder: 'descend',
    },
  });

  const queryParams = toApiParams(params);
  const {
    data,
    isFetching,
    isLoading,
    refetch: refetchProjects,
  } = useGetProjectListQuery(queryParams);

  const openCreateProjectModal = () => {
    dispatch(
      toggleSearchModal({
        value: true,
      })
    );
  };

  const openDeleteModalConfirm = (id: string) => {
    return (event: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
      blockEvent(event);
      setSelectedAnzaId(id);
    };
  };

  const closeDeleteModalCancel = () => setSelectedAnzaId('');

  const closeDeleteModalConfirm = async () => {
    await deleteProject({ id: selectedAnzaId });
    setSelectedAnzaId('');
    refetchProjects();
    setRefresh((state) => state + 1);
  };

  const handleTableChange = (
    newPagination: TablePaginationConfig,
    newFilters: Record<string, FilterValue | null>,
    newSorter: SorterResult<any> | SorterResult<any>[]
  ) => {
    const paramsMap: Record<string, any> = {};

    if (!R.isEmpty(newFilters)) {
      if (newFilters.status) {
        const newStatus = [...newFilters.status] as string[];
        if (newStatus.includes(PROJECT_STATUSES_BUYER.inactive.key)) {
          newStatus.push(PROJECT_STATUSES.awarded.key);
          if (isAdmin) {
            newStatus.push(PROJECT_STATUSES.lost.key);
          }
        }
        paramsMap.filters = { ...newFilters, status: newStatus };
      } else {
        paramsMap.filters = newFilters;
      }
    }

    if (!R.isEmpty(newPagination)) {
      paramsMap.pagination = newPagination;
    }

    if (!R.isEmpty(newSorter)) {
      const sorterObject = Array.isArray(newSorter) ? newSorter[0] : newSorter;
      paramsMap.sorter = {
        sortField: sorterObject.field,
        sortOrder: sorterObject.order,
      };
    }

    if (!R.isEmpty(paramsMap)) {
      dispatchParams({
        data: paramsMap as Partial<InitialState>,
        type: 'update',
      });
    }
  };

  useEffect(() => {
    if (data) {
      history.replace({
        pathname: history.location.pathname,
        search: `?${queryParams.toString()}`,
      });
      dispatchParams({
        data: {
          pagination: { total: data.count, showSizeChanger: false },
        } as Partial<InitialState>,
        type: 'count',
      });
    }
  }, [isFetching]);

  useEffect(() => {
    dispatch(
      setPageBannerAction({ pageBanner: BANNER_TYPES.SEE_MORE_BUY_BETTER })
    );
    return () => {
      dispatch(removePageBannerAction());
    };
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const columnTitles = document.querySelectorAll(
      '.ant-table-column-sorters > .ant-table-column-title'
    );
    const columnFilters = document.querySelectorAll(
      '.ant-table-filter-column > .ant-table-filter-trigger'
    );

    Array.from(columnTitles).forEach((element, index) => {
      element.setAttribute(
        'data-testid',
        generateTestId({
          ...dataTestIdConfig,
          component: TEST_DATA_COMPONENTS.TABLE_HEADER,
          identifier: 'title',
          indices: index.toString(),
        })
      );
    });

    Array.from(columnFilters).forEach((element, index) => {
      element.setAttribute(
        'data-testid',
        generateTestId({
          ...dataTestIdConfig,
          component: TEST_DATA_COMPONENTS.TABLE_HEADER,
          identifier: 'filter',
          indices: index.toString(),
        })
      );
    });
  }, [isLoading]);

  useEffect(() => {
    dispatch(resetSearchModal());
  }, [refresh]);

  const handleSearch = useCallback(
    debounce((selectedKeys: string[], dataIndex: string) => {
      const action = {
        data: {
          filters: { [dataIndex]: selectedKeys },
        } as unknown as Partial<InitialState>,
        type: 'update',
      };
      dispatchParams(action);
    }, 300),
    []
  );

  const columns = getColumns({
    handleSearch,
    openDeleteModalConfirm,
  });

  const Empty = () => (
    <EmptyTableContainer>
      <EmptyTitle data-testid="empty-projects-title">
        Let&apos;s get to work.
      </EmptyTitle>
      <EmptyDescription>
        Schedule a call or start your search below.
      </EmptyDescription>
      <div style={{ display: 'flex' }}>
        <ActionButton
          onClick={openCreateProjectModal}
          data-testid={generateTestId({
            ...dataTestIdConfig,
            component: TEST_DATA_COMPONENTS.BUTTON,
            identifier: 'new-project-table',
          })}
        >
          <IconAdd />
          New Project
        </ActionButton>
      </div>
    </EmptyTableContainer>
  );

  const handleRowClick = (record: ProjectResume) => {
    if (record.project_type === 'solar') {
      return history.push(
        ROUTER_PATHS.projects.solar.PROJECT_DETAILS(record.uuid)
      );
    }

    return history.push(
      ROUTER_PATHS.projects.storage.PROJECT_DETAILS(record.uuid)
    );
  };

  return (
    <Container>
      <Row
        style={{
          padding: '24px 0',
        }}
      >
        {showPricingVisualization && !newProjectFlow && (
          <PricingHistoryBanner />
        )}
        <ActionRow>
          <div className="table">
            <Title
              data-testid={generateTestId({
                ...dataTestIdConfig,
                component: TEST_DATA_COMPONENTS.TEXT,
                identifier: 'projects-title',
              })}
            >
              Projects
            </Title>
          </div>
          <div className="nav">
            <CreateProject
              testIdData={dataTestIdConfig}
              loaded={!isLoading}
              primaryButton
            />
          </div>
        </ActionRow>
      </Row>
      <ConfigProvider renderEmpty={Empty}>
        <ProjectTable
          className="alternate-rows-even"
          columns={
            newProjectFlow
              ? columns?.filter((column) => column.key !== 'project_type')
              : columns
          }
          dataSource={data?.items}
          loading={isLoading}
          pagination={params.pagination}
          rowKey="uuid"
          scroll={{ x: R.isEmpty(data?.items) ? undefined : '100%' }}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </ConfigProvider>
      {drawerVisible && (
        <StyledDrawer
          className="no-padded-header"
          title={
            <CustomDrawerHeader
              title="Schedule A Call"
              description="Speak with a Borrego representative about your project. I want to talk
          about:"
            />
          }
          placement="bottom"
          onClose={() => setDrawerVisible(false)}
          height={theme.elements.drawer.height}
          open={drawerVisible}
        >
          <ScheduleCallDialog />
        </StyledDrawer>
      )}
      {!!selectedAnzaId && (
        <DeleteModalConfirm
          onOk={closeDeleteModalConfirm}
          onCancel={closeDeleteModalCancel}
          visible
        />
      )}
    </Container>
  );
};

export default ProjectsList;
